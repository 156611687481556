import React, { useState } from "react";
import contact1 from "./contact1.png";
import "./Contact.css";
import hero from "../../pic/hero.png";

const Contact = () => {
  const [data, setData] = useState({
    fullname: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    alert(
      `My name is ${data.fullname}. 
	My phone number is ${data.phone}. 
	My email address is ${data.email}. 
	My Subject on  ${data.subject}. 
	Here is my message I want to say : ${data.message}. 
	`
    );
  };
  return (
    <>
      <section className="Contact" id="contact">
        <div className="container top">
          <div className="heading text-center">
            <h4>CONTACT</h4>
            <h1>With Me</h1>
          </div>

          <div className="contactMain">
            <div className="left">
              <div className="box box_shodow">
                <div className="details">
                  {/* <h2>Shalev Lazarof</h2> */}
                  {/* <br /> */}
                  <p>
                    <span style={{ fontWeight: "bold" }}>Address:</span> Ramat
                    Gan
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    0526206091
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Email: </span>
                    lazarof.shalev@icloud.com
                  </p>
                </div>
              </div>
              <p className="footer"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
