import image1 from "../../image/portfolio-01.png";
import image2 from "../../image/portfolio-02.png";
import image3 from "../../image/portfolio-03.png";
import image4 from "../../image/portfolio-04.png";
import image5 from "../../image/portfolio-05.png";
import image6 from "../../image/portfolio-06.jpg";
import image7 from "../../image/portfolio-07.png";
import image8 from "../../image/portfolio-08.png";

const Portfolio_data = [
  {
    id: 1,
    category: "React | Material UI",
    title: "Travel Advisor",
    image: image1,
    desc: "Get all restaurants, hotels, and attractions available around you or by a location search, get all the relevant data such as ranking, price range, location etc.",
    project_url: "https://travel-advisor-shalev-lazarof.netlify.app",
    project_git: "https://github.com/ShalevL/Travel-Advisor",
  },
  {
    id: 2,
    category: "React | Redux | Tailwind CSS",
    title: "Google Clone",
    image: image2,
    desc: "Search a term and view websites, news, images and videos, toggle dark/light mode.",
    project_url: "https://google-clone-shalev-lazarof.netlify.app",
    project_git: "https://github.com/ShalevL/Google-Clone",
  },
  {
    id: 3,
    category: "React | Redux | Ant Design ",
    title: "Crypto Verse",
    image: image3,
    desc: "Get all the latest information of all crypto coins such as news, rank, currency etc.",
    project_url: "https://crypto-verse-shalev-lazarof.netlify.app",
    project_git: "https://github.com/ShalevL/Crypro-Verse",
  },
  {
    id: 4,
    category: "React (Firebase as a backend)",
    title: "Food Order",
    image: image4,
    desc: "Add selected meals to an order and make a reservation.",
    project_url: "https://food-order-shalev-lazarof.netlify.app",
    project_git: "https://github.com/ShalevL/Food-Order",
  },
  {
    id: 5,
    category: "React | Redux | Bootstrap",

    title: "My Weather",
    image: image5,
    desc: "View the weather condition for today and the next 5 days for your current location or by a city search, add & remove favorites locations.",
    project_url: "",
    project_git: "https://github.com/ShalevL/My-Weather",
  },
  {
    id: 6,
    category: "C | MPI | OpenMP | CUDA",

    title: "Binary Classification",
    image: image6,
    desc: "Parallel and distributed binary classification algorithm to find a Linear Classifier. ",
    project_url: "",
    project_git:
      "https://github.com/ShalevL/Parallel-Binary-Classification/blob/master/README.md",
  },
  {
    id: 7,
    category: "C++ | OpenGL",

    title: "Artificial intelligence",
    image: image7,
    desc: "Demonstrate smart behavior of virtual characters in a maze based on A* search (A-Star) algorithm.",
    project_url: "",
    project_git: "https://github.com/ShalevL/Artificial-Intelligence-Project",
  },
  {
    id: 8,
    category: "Python | Spark ML",

    title: "Machine Learning Seminar",
    image: image8,
    desc: "Database analysis to predict a parameter using machine learning algorithms.",
    project_url: "",
    project_git:
      "https://github.com/ShalevL/FatalCarCrashPredictionMachineLearningSeminar/blob/master/README.md",
  },
];
export default Portfolio_data;
