const ResumeApi = [
  {
    id: 1,
    category: "education",
    year: "B.Sc. Software Engineering (2016 - 2021)",
    title: "Afeka College of Engineering",
    desc: "Major in Software and information Technologies",
    rate: "4.30/5",
  },


  {
    id: 2,
    category: "education",
    year: "Jose Portilla (2022)",
    title: "Python - The Complete Guide",
    desc: "",
    rate: "4.30/5",
  },


  {
    id: 3,
    category: "education",
    year: "ACADEMIND  (2021)",
    title: "NodeJS - The Complete Guide",
    desc: "",
    rate: "4.30/5",
  },
  {
    id: 4,
    category: "education",
    year: "ACADEMIND  (2022)",
    title: "The Complete React Developer",
    desc: "",
    rate: "4.30/5",
  },
  {
    id: 5,
    category: "education",
    year: "ACADEMIND  (2021 - 2022)",
    title: "The Complete JavaScript Course",
    desc: "",
    rate: "4.30/5",
  },
  {
    id: 6,
    category: "education",
    year: "UDEMY (2021)",
    title: "The Complete Web Development Bootcamp",
    desc: "",
    rate: "4.30/5",
  },
  

  {
    id: 7,
    category: "experience",
    year: "June 2022 - Present",
    title: "McCann",
    desc: "Frontend Developer",
    rate: "4.70/5 ",
  }
  // ,
  // {
  //   id: 8,
  //   category: "experience",
  //   year: "(2019 - 2020)",
  //   title: "Sales Representative, iDigital",
  //   desc: "",
  //   rate: "4.95/5 ",
  // },
  // {
  //   id: 9,
  //   category: "experience",
  //   year: "(2008 - 2016)",
  //   title: "Retail Store, Nahariya",
  //   desc: "Shift manager, recruiting and training new employees, Inventory system and media campaigns manager",
  //   rate: "5.00/5 ",
  // },
];

export default ResumeApi;
