import React from "react";
import "./Home.css";

// import skill1 from "../pic/skill1.png";
// import skill2 from "../pic/skill2.png";
// import skill3 from "../pic/skill3.png";
// import skill4 from "../pic/skill4.png";
// import skill5 from "../pic/skill5.png";
// import skill6 from "../pic/skill6.png";
import find1 from "../../pic/find1.png";
import find2 from "../../pic/find2.png";
import find3 from "../../pic/find3.png";
import find4 from "../../pic/find4.jpg";
import { Typewriter } from "react-simple-typewriter";
import hero from "../../pic/hero.png";
import heroShalev from "../../pic/heroShalev.jpg";

const Home = () => {
  return (
    <>
      <section className="hero" id="home">
        <div className="container f_flex top">
          <div className="left top">
            <h1>
              Hi, I’m <span>Shalev</span>
            </h1>
            <h2>
              <span className="a_span">a</span>
              <span>
                <Typewriter
                  words={[" software engineer."]}
                  loop={0 | true}
                  cursor
                  cursorStyle="|"
                  typeSpeed={200}
                  deleteSpeed={50}
                  delaySpeed={1500}
                />
              </span>
            </h2>

            <p style={{ fontWeight: "bold" }}>
            software engineer with strong foundation in programming principles across several platforms and self-learning abilities for quickly mastering new technologies, have the passion and the will to achieve any goal.
            </p>

            <div className="hero_btn d_flex">
              {/* <a
                style={{ fontWeight: "bold" }}
                className="home-btn"
                download="ShalevLazarofCV"
                href={require("../../files/ShalevLazarofCV.pdf")}
              >
                DOWNLOAD CV
              </a> */}
            </div>
            <div className="hero_btn d_flex">
              <div className="col_1">
                <button
                  className="btn_shadow"
                  onClick={function () {
                    window.open(
                      "https://www.linkedin.com/in/shalev-tal-lazarof-3ba0515a/"
                    );
                  }}
                >
                  <img src={find1} alt="" />
                </button>
                <button
                  className="btn_shadow"
                  onClick={function () {
                    window.open("https://github.com/ShalevL?tab=repositories");
                  }}
                >
                  <img src={find2} alt="" />
                </button>
                <button
                  className="btn_shadow"
                  onClick={function () {
                    window.open("mailto:lazarof.shalev@icloud.com");
                  }}
                >
                  <img src={find3} alt="" />
                </button>
              </div>
            </div>

            <div className="skills">
              <h1>SKILLS</h1>
              <h2>Software Languages</h2>
              <h3 style={{ fontWeight: "bold" }}>
                JavaScript | HTML/CSS | C | Java | Python | Swift | Scala | PHP
              </h3>
              <h2>Frameworks & Others</h2>
              <h3 style={{ fontWeight: "bold" }}>
                React | Redux | Bootstrap | Material UI | Ant Design | Tailwind
                CSS | Git | JSON | SQL | Firebase | RESTful API
              </h3>
            </div>
          </div>
          {/* <div className="right">
            <div className="right_img">
              <img src={hero} alt="" />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Home;
