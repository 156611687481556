import { Fragment } from "react";
import Header from "./components/Header/Header";
import Home from "./components/Hero/Home";
import Features from "../src/components/Features/Features";
import Portfolio from "../src/components/Portfolio/Portfolio";
import Resume from "../src/components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import "./App.css";
function App() {
  return (
    <Fragment>
      <Header />
      <Home />
      {/* <Features /> */}
      <Portfolio />
      <Resume />
      <Contact />
    </Fragment>
  );
}

export default App;
